import React from "react";

const IconInstagram = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <title>Instagram</title>
        <path d="M10.5 1.895c2.803 0 3.135.01 4.242.06 1.023.047 1.58.22 1.95.362.49.19.838.418 1.206.785.367.368.594.717.785 1.207.143.37.314.92.36 1.94.052 1.103.062 1.44.062 4.24s-.01 3.132-.06 4.24c-.048 1.022-.22 1.58-.362 1.95-.19.49-.418.84-.785 1.205-.368.367-.717.594-1.207.785-.37.143-.92.314-1.94.36-1.103.05-1.44.06-4.24.06s-3.132-.01-4.24-.06c-1.022-.046-1.58-.22-1.95-.36-.49-.19-.84-.42-1.205-.785-.367-.368-.594-.717-.785-1.207-.143-.37-.315-.926-.36-1.95-.05-1.107-.06-1.44-.06-4.242 0-2.804.01-3.136.06-4.243.045-1.02.22-1.58.36-1.95.19-.49.42-.835.785-1.203.368-.368.717-.595 1.207-.786.37-.142.926-.314 1.95-.36 1.107-.05 1.44-.06 4.242-.06m0-1.893c-2.85 0-3.207.01-4.327.065-1.12.05-1.883.23-2.55.487-.69.27-1.275.63-1.86 1.21-.582.586-.94 1.17-1.21 1.86-.26.67-.437 1.43-.49 2.55C.017 7.292.003 7.65.003 10.5s.013 3.208.064 4.328c.05 1.117.23 1.88.488 2.548.27.69.628 1.275 1.21 1.86.585.582 1.17.94 1.86 1.21.668.26 1.43.437 2.548.488 1.12.05 1.477.063 4.328.063 2.85 0 3.21-.012 4.33-.063 1.12-.05 1.88-.23 2.55-.488.69-.27 1.28-.628 1.86-1.21.58-.585.94-1.17 1.21-1.86.26-.668.44-1.43.49-2.548.05-1.12.066-1.477.066-4.328 0-2.85-.012-3.208-.063-4.328-.05-1.117-.23-1.88-.49-2.548-.27-.69-.63-1.275-1.21-1.86-.584-.582-1.17-.94-1.86-1.21-.67-.26-1.43-.437-2.55-.488-1.12-.05-1.48-.063-4.33-.063" />
        <path d="M10.5 5.11c-2.977 0-5.39 2.413-5.39 5.39s2.413 5.39 5.39 5.39 5.39-2.413 5.39-5.39-2.413-5.39-5.39-5.39zm0 8.89C8.568 14 7 12.43 7 10.5S8.57 7 10.5 7 14 8.57 14 10.5 12.43 14 10.5 14zm6.863-9.103c0 .696-.564 1.26-1.26 1.26-.696 0-1.26-.564-1.26-1.26 0-.696.565-1.26 1.26-1.26.696 0 1.26.564 1.26 1.26" />
    </svg>
);

export default IconInstagram;
